@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&family=Rubik+Moonrocks&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-[Raleway];
    }

    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}